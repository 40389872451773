<template>
  <b-modal
    id="withdraw-game-credit-modal"
    ref="withdraw-game-credit-modal"
    hide-footer
    no-close-on-backdrop
    title="ถอนเครดิตเกม"
    @show="setData"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <div class="mb-2">
        <span>บัญชี</span>
        <div class="deposit-username text-center alert alert-info p-1"><h2 class="m-0">{{ username }}</h2></div>
      </div>
      <div class="mb-2">
        <span>ยอดคงเหลือ</span>
        <div class="deposit-balance text-center alert alert-danger p-1"><h2 class="m-0">{{ balance | amountFormat(2, '0.00') }}</h2></div>
      </div>
      <b-form-group
        label="จำนวนเครดิต"
      >
        <b-form-input
          name="amount"
          v-model="input.amount"
          type="number"
          size="lg"
          :number="true"
          required
        ></b-form-input>
      </b-form-group>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="danger" :disabled="isProcess">{{isProcess ? 'กำลังถอนเครดิต...' : 'ถอนเครดิต'}}</b-button>
      </div>

    </form>
  </b-modal>
</template>
<script>
import GameService from '@/services/gameService'
import Swal from 'sweetalert2'
import cryptoRandomString from 'crypto-random-string'

export default {
  name: 'WithdrawGameCreditModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      gameAccount: null,
      input: {
        uniqueCode: '',
        amount: null
      },
      isProcess: false,
      needReload: false
    }
  },
  computed: {
    accountId() {
      return this.data?._id ?? null
    },
    username() {
      if(this.data) {
        return this.data.users[0].username
      }else{
        return ''
      }
    },
    balance() {
      return this.gameAccount?.balance ?? 0
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.$refs['withdraw-game-credit-modal'].show()
      }else{
        this.$refs['withdraw-game-credit-modal'].hide()
      }
    }
  },
  methods: {
    loadGameAccount() {
      GameService.gameAccount(this.accountId)
      .then((response)=>{
        if(response.success) {
          this.gameAccount = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        this.$refs['withdraw-game-credit-modal'].hide()
      })
    },
    setData() {
      this.needReload = false
      this.input = {
        amount: null,
        uniqueCode: cryptoRandomString({length: 12, type: 'alphanumeric'})
      }

      this.loadGameAccount()
    },
    hideModal() {
      this.$refs['withdraw-game-credit-modal'].hide()
    },
    handleSubmit() {
      if(this.balance < this.input.amount || this.input.amount < 0) {
        return Swal.fire({
          text: 'จำนวนเครดิตไม่ถูกต้อง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      this.isProcess = true
      GameService.withdraw(this.accountId, this.input)
      .then((response)=>{
        if(response.success) {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'ถอนเครดิตเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response?.data)
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: e?.message || 'ถอนเครดิตไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
        this.input.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
