<template>
  <div class="container-fluid">
    <div class="page-header d-flex justify-content-between align-items-center mb-1">
      <strong>บัญชีบริษัท</strong>
      <button class="btn btn-sm btn-link" @click="addAccount">เพิ่มบัญชีบริษัท</button>
    </div>
    <div class="card">
      <table class="table table-sm table-hover mb-0 account-company-table">
        <thead>
          <tr>
            <th class="text-center">ลำดับ</th>
            <th class="text-center">ชื่อผู้ใช้</th>
            <th class="text-center">ระดับ</th>
            <th class="text-center">ชื่อสมาชิก</th>
            <th class="text-center">ยอดเงิน</th>
            <th class="text-center">เครดิตเกม</th>
            <th class="text-center">วันที่สมัคร</th>
            <th class="text-center">เข้าใช้งานล่าสุด</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="item._id">
            <td class="text-center">{{ index + 1 }}</td>
            <td class="text-center">{{ username(item.users) }}</td>
            <td class="text-center">{{ level(item) }}</td>
            <td class="text-center">{{ fullname(item.users) }}</td>
            <td class="text-center text-success">{{ balance(item.balance) }}</td>
            <td class="text-center text-primary">{{ gameCredit(item) | amountFormat(2, '0.00') }}</td>
            <td class="text-center">
              {{ registerDateTime(item.users, 'createdAt', 'DD/MM/YYYY HH:mm:ss') }}
            </td>
            <td class="text-center">
              {{ registerDateTime(item.users, 'lastActive', 'DD/MM/YYYY HH:mm:ss') }}
            </td>
            <td class="text-center">
              <b-dropdown class="m-0 p-0 btn-edit" size="sm" variant="link" right>
                <template slot="button-content">
                  <i class="fas fa-ellipsis-v"></i>
                </template>

                <b-dropdown-item @click="editProfile(item)">
                  <template>
                    <span class="text-primary"><i class="far fa-address-card"></i> แก้ไขข้อมูลบริษัท</span>
                  </template>
                </b-dropdown-item>
                <b-dropdown-item @click="changePassword(item)">
                  <template>
                    <span class="text-primary"><i class="fas fa-key"></i> เปลี่ยนรหัสผ่าน</span>
                  </template>
                </b-dropdown-item>
                <b-dropdown-item @click="deposit(item)">
                  <template>
                    <span class="text-success"><i class="far fa-plus-square"></i> เติมเงิน</span>
                  </template>
                </b-dropdown-item>
                <b-dropdown-item @click="withdraw(item)">
                  <template>
                    <span class="text-danger"><i class="far fa-minus-square"></i> ถอนเงิน</span>
                  </template>
                </b-dropdown-item>

                <b-dropdown-item v-if="gameAvailable(item)" @click="depositGame(item)">
                  <template>
                    <span class="text-success"><i class="fas fa-gamepad"></i> เติมเครดิตเกม</span>
                  </template>
                </b-dropdown-item>
                <b-dropdown-item v-if="gameAvailable(item)" @click="withdrawGame(item)">
                  <template>
                    <span class="text-danger"><i class="fas fa-gamepad"></i> ถอนเครดิตเกม</span>
                  </template>
                </b-dropdown-item>

              </b-dropdown>
            </td>
          </tr>
          <tr v-if="!items.length">
            <td colspan="8" class="text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
      </table>
    </div>

    <AccountCompanyModal :data="item" :levels="levels" :is-show="isShowModal" @close="modalClosed" />
    <EditProfileModal :data="editItem" :is-show="isShowEditModal" @close="modalClosed" />
    <ChangePasswordModal :data="changePasswordItem" :is-show="isShowPasswordModal" @close="modalClosed" />
    <DepositModal :data="depositItem" :is-show="isShowDepositModal" @close="modalClosed" />
    <WithdrawModal :data="withdrawItem" :is-show="isShowWithdrawModal" @close="modalClosed" />

    <DepositGameCreditModal :data="modalGameItem" :is-show="isShowDepositGameModal" @close="modalClosed" />
    <WithdrawGameCreditModal :data="modalGameItem" :is-show="isShowWithdrawGameModal" @close="modalClosed" />
  </div>
</template>
<script>
import AccountService from "@/services/accountService"
import moment from '@/services/moment'

import AccountCompanyModal from './components/AccountCompanyModal'
import EditProfileModal from './components/EditProfileModal'
import ChangePasswordModal from './components/ChangePasswordModal'
import DepositModal from './components/DepositModal'
import WithdrawModal from './components/WithdrawModal'

import DepositGameCreditModal from '@/views/manage-game/components/DepositGameCreditModal'
import WithdrawGameCreditModal from '@/views/manage-game/components/WithdrawGameCreditModal'

export default {
  name: 'AccountCompanies',
  components: {
    AccountCompanyModal,
    EditProfileModal,
    ChangePasswordModal,
    DepositModal,
    WithdrawModal,
    DepositGameCreditModal,
    WithdrawGameCreditModal
  },
  data() {
    return {
      isShowModal: false,
      isShowEditModal: false,
      isShowPasswordModal: false,
      defaultInput: null,
      item: null,
      items: [],
      levelDatas: [],
      editItem: {
        _id: null,
        username: null,
        firstName: null,
        lastName: null,
        tel: null,
        lineId: null
      },
      changePasswordItem: {
        _id: null,
        username: null,
        new_password: null,
        confirm_password: null
      },
      /**
       * เติมเงิน
       * @type {Object}
       */
      isShowDepositModal: false,
      depositItem: null,
      /**
       * ถอนเงิน
       * @type {Object}
       */
      isShowWithdrawModal: false,
      withdrawItem: null,
      /**
       * เครดิตเกม
       */
      isShowDepositGameModal: false,
      isShowWithdrawGameModal: false,
      modalGameItem: null
    }
  },
  computed: {
    levels() {
      return this.levelDatas.filter((level)=>{
        return level.status === 'Active'
      })
    }
  },
  methods: {
    addAccount() {
      this.item = JSON.parse(JSON.stringify(this.defaultInput))
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      this.isShowEditModal = false
      this.isShowPasswordModal = false
      this.isShowDepositModal = false
      this.isShowWithdrawModal = false
      this.isShowDepositGameModal = false
      this.isShowWithdrawGameModal = false
      if(needReload) {
        this.loadAccounts()
      }
    },
    loadAccountLevels() {
      AccountService.getLevels()
      .then((response)=>{
        if(response.success) {
          this.levelDatas = response.data
        }
      })
    },
    loadAccountTemplate() {
      AccountService.getAccountTemplate()
      .then((response)=>{
        if(response.success) {
          this.defaultInput = response.data
        }
      })
    },
    loadAccounts() {
      AccountService.getAccounts()
      .then((response)=>{
        console.log(response)
        if(response.success) {
          this.items = response.data
        }
      })
    },
    username(users) {
      if(users.length > 0) {
        return users[0].username
      }else{
        return '-'
      }
    },
    fullname(users) {
      if(users.length > 0) {
        return `${users[0].firstName} ${users[0].lastName}`
      }else{
        return '-'
      }
    },
    balance(amount) {
      return amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    gameCredit(item) {
      return item?.gameAccount?.[0]?.balance ?? 0
    },
    level(item) {
      return item?.levels[0]?.levelName ?? '-'
    },
    registerDateTime(users, filed, format) {
      if(users.length > 0) {
        if(users[0][filed]) {
          return moment(users[0][filed]).format(format)
        }else{
          return '-'
        }
      }else{
        return '-'
      }
    },
    editProfile(item) {
      console.log(item)
      this.editItem = Object.keys(this.editItem)
        .reduce((obj, key) => {
          obj[key] = item?.users?.[0]?.[key] || ''
          return obj
        }, {});
      this.isShowEditModal = true
    },
    changePassword(item) {
      console.log(item)
      this.changePasswordItem = {
        ...this.changePasswordItem,
        ...item?.users?.[0]
      }
      this.isShowPasswordModal = true
    },
    deposit(item) {
      console.log(item)
      this.depositItem = item
      this.isShowDepositModal = true
    },
    withdraw(item) {
      console.log(item)
      this.withdrawItem = item
      this.isShowWithdrawModal = true
    },
    gameAvailable(item) {
      return item?.gameAccount?.[0]?.isAvailable ?? false
    },
    depositGame(item) {
      this.modalGameItem = item
      this.isShowDepositGameModal = true
    },
    withdrawGame(item) {
      this.modalGameItem = item
      this.isShowWithdrawGameModal = true
    }
  },
  mounted() {
    this.loadAccountLevels()
    this.loadAccountTemplate()
    this.loadAccounts()
    this.$store.dispatch('reloadMarketGroups')
    this.$store.dispatch('reloadMarketPayrates')
  }
}
</script>
<style lang="scss" scoped>
.container {
  .table {
    thead {
      tr {
        th {
          font-size: 90%;
          font-weight: normal;
        }
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          height: 40px;
        }
        button {
          display: none;
        }
      }
      tr:hover {
        button {
          display: inline-table;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.account-company-table {
  button.dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu.dropdown-menu-right {
    li {
      font-size: 90%;
    }
  }
}
</style>
